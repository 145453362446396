import React, { useEffect, useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import musicNoteIcon from '../../../assets/images/ui/music-note.svg';
import { AudioType } from '../../../dorian-shared/types/audio/audio';
import {
  AudioStepAction,
  AudioStepSettingsType,
  MusicActionSettings,
  SFXActionSettings,
} from '../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudioMusicContainer } from './AudioMusicContainer/AudioMusicContainer';
import { AudioSFXContainer } from './AudioSFXContainer/AudioSFXContainer';
import classes from './AudioStepSettingsModal.module.scss';
import { fallbackMusicValue, fallbackSFXValue } from './utils';

export interface AudioStepSettingsModalProps {
  value: AudioStepSettingsType;
  onChange: (value: AudioStepSettingsType) => void;
  onHide: () => void;
}

export function AudioStepSettingsModal(props: AudioStepSettingsModalProps) {
  const { value } = props;
  const [show, setShow] = useState(false);
  const [audioStepSettings, setAudioStepSettings] = useState<AudioStepSettingsType>(value);

  useEffect(() => {
    setAudioStepSettings(value);
  }, [value]);

  const handleOnHide = () => {
    const { onHide } = props;
    setShow(false);
    setAudioStepSettings(value);
    onHide?.();
  };

  const handleSave = () => {
    const { onChange } = props;
    onChange?.(audioStepSettings);
    handleOnHide();
  };

  const handleAudioChange = (rowIndex: number, settings: SFXActionSettings | MusicActionSettings) => {
    const newAudioStepSettings = [...audioStepSettings];
    newAudioStepSettings[rowIndex] = settings;
    setAudioStepSettings(newAudioStepSettings);
  };

  const sfxValue: SFXActionSettings = audioStepSettings.find((action) => action?.type === AudioType.SFX) as SFXActionSettings ?? fallbackSFXValue;
  const musicValue: MusicActionSettings = audioStepSettings.find((action) => action?.type === AudioType.Music) as MusicActionSettings ?? fallbackMusicValue;

  const audioCount = value.filter((a) => a?.action && a.action !== AudioStepAction.None).length;

  return (
    <div style={{ position: 'relative' }}>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setShow(!show)}
      >
        <img
          src={musicNoteIcon}
          alt="Music note"
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      </Button>
      {audioCount > 0 && (
        <span className="boxBadge" style={{ top: '-8px', right: '-8px' }}>
          <Badge
            pill
            variant="light"
          >
            {audioCount}
          </Badge>
        </span>
      )}
      <Modal
        show={show}
        onHide={handleOnHide}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName={classes.contentClassName}
        dialogClassName={classes.dialogClassName}
      >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex">
            Audio Step Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AudioSFXContainer
            value={sfxValue}
            onChange={(settings) => handleAudioChange(0, settings)}
          />
          <AudioMusicContainer
            value={musicValue}
            onChange={(settings) => handleAudioChange(1, settings)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleOnHide}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
