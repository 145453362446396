import React, { useContext, useEffect, useRef } from 'react';
import Select, { SelectInstance } from 'react-select';
import { AudioContextType, AudioType } from '../../../../dorian-shared/types/audio/audio';
import {
  AudioSelectOption,
  AudioStepAction,
  MusicActionSettings,
} from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { actionMusicOptions, getAudioOptions } from '../utils';

export type AudioMusicSettingsProps = {
  value: MusicActionSettings;
  onChange: (value: MusicActionSettings) => void;
  focused?: boolean;
};

export function AudioMusicSelect(props: AudioMusicSettingsProps) {
  const { value, onChange, focused } = props;

  const selectRef = useRef<SelectInstance<AudioSelectOption | null>>(null);

  const {
    audios,
  } = useContext<AudioContextType>(AudiosContext);

  useEffect(() => {
    if (focused) {
      selectRef.current?.focus();
    }
  }, [focused]);

  const audioOptions = getAudioOptions(audios, AudioType.Music);

  const options = [
    {
      label: 'ACTIONS',
      options: actionMusicOptions,
    },
    {
      label: 'MUSIC',
      options: audioOptions,
    },
  ];

  const regularValue = audioOptions.find((option) => option.value === value.audioId);
  const currentValue = regularValue
    ?? actionMusicOptions.find((option) => option.value === value.action)
    ?? actionMusicOptions[0];

  return (
    <div className="w-100">
      <Select
        id="audio-sfx-select"
        ref={selectRef}
        options={options}
        value={currentValue}
        onChange={(selectedOption) => {
          const isAction = selectedOption?.action !== AudioStepAction.Play;
          onChange({
            type: AudioType.Music,
            action: selectedOption?.action ?? AudioStepAction.None,
            audioUrl: selectedOption?.audioUrl ?? '',
            audioId: isAction ? null : selectedOption?.value ?? null,
            loop: 0,
          });
        }}
      />
    </div>
  );
}
