import React, { useContext } from 'react';
import {
  Alert, Col, Form, Row,
} from 'react-bootstrap';
import { AudioContextType } from '../../../../dorian-shared/types/audio/audio';
import { AudioStepAction, MusicActionSettings } from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { AudioMusicSelect } from '../AudioMusicSelect/AudioMusicSelect';
import { AudioPlay } from '../AudioPlay/AudioPlay';

type MusicContainerProps = {
  value: MusicActionSettings,
  onChange: (value: MusicActionSettings) => void,
}

export function AudioMusicContainer(props: MusicContainerProps) {
  const { value, onChange } = props;

  const { audios } = useContext<AudioContextType>(AudiosContext);
  const currentAudio = audios.find((audio) => audio.id === value.audioId);

  const isDisabled = value.action === AudioStepAction.None
    || value.action === AudioStepAction.Stop
    || value.audioId === null;

  return (
    <>
      <Alert variant="info" className="text-center p-0 mt-4 mb-2">
        Background Music
      </Alert>
      <Row>
        <Col>
          <AudioMusicSelect
            value={value}
            onChange={onChange}
          />
        </Col>
        <Col sm="auto">
          <AudioPlay
            className="h-100"
            url={currentAudio?.audioUrl ?? ''}
            settings={{ loop: value.loop ?? 0 }}
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-1">
          <Form.Check
            custom
            id="audio-music-loop"
            label="Loop"
            checked={value.loop > 0}
            onChange={(e) => {
              const newValue = { ...value };
              newValue.loop = e.target.checked ? 1 : 0;
              onChange(newValue);
            }}
            disabled={isDisabled}
          />
        </Col>
      </Row>
    </>
  );
}
