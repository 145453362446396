import React, { useContext, useEffect, useRef } from 'react';
import Select, { SelectInstance } from 'react-select';
import { AudioContextType, AudioType } from '../../../../dorian-shared/types/audio/audio';
import {
  AudioSelectOption,
  AudioStepAction,
  SFXActionSettings,
} from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { actionSFXOptions, getAudioOptions } from '../utils';

export type AudioSFXSettingsProps = {
  value: SFXActionSettings;
  onChange: (value: SFXActionSettings) => void;
  focused?: boolean;
};

export function AudioSFXSelect(props: AudioSFXSettingsProps) {
  const { value, onChange, focused } = props;

  const selectRef = useRef<SelectInstance<AudioSelectOption | null>>(null);

  const {
    audios,
  } = useContext<AudioContextType>(AudiosContext);

  useEffect(() => {
    if (focused) {
      selectRef.current?.focus();
    }
  }, [focused]);

  const audioOptions = getAudioOptions(audios, AudioType.SFX);
  const options = [
    {
      label: 'ACTIONS',
      options: actionSFXOptions,
    },
    {
      label: 'SOUND EFFECTS',
      options: audioOptions,
    },
  ];

  const regularValue = audioOptions.find((option) => option.value === value.audioId);

  const currentValue = regularValue
    ?? actionSFXOptions.find((option) => option.value === value.action)
    ?? actionSFXOptions[0];

  return (
    <div className="w-100">
      <Select
        id="audio-sfx-select"
        ref={selectRef}
        options={options}
        value={currentValue}
        onChange={(selectedOption) => {
          const isAction = selectedOption?.action !== AudioStepAction.Play;
          onChange({
            type: AudioType.SFX,
            action: selectedOption?.action ?? AudioStepAction.None,
            audioUrl: selectedOption?.audioUrl ?? '',
            audioId: isAction ? null : selectedOption?.value ?? null,
          });
        }}
      />
    </div>
  );
}
