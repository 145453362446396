import React, {
  createContext, useContext, useMemo,
} from 'react';
import { useAudios } from './useAudios';

export const AudiosContext = createContext();

const useUploadAudioContext = () => useContext(AudiosContext);

export const useRefetchAudio = () => {
  const { refetchAudio } = useUploadAudioContext();
  return refetchAudio;
};

export function AudiosProvider({ children }) {
  const {
    audios,
    isLoading,
    refetchAudio,
    currentPage,
    setCurrentPage,
    totalCount,
    handleSearchSubmit,
    searchTerm,
  } = useAudios();

  const contextValue = useMemo(
    () => ({
      currentPage,
      setCurrentPage,
      totalCount,

      audios,
      isLoading,
      refetchAudio,

      handleSearchSubmit,
      searchTerm,
    }),
    [
      currentPage,
      setCurrentPage,
      totalCount,

      audios,
      isLoading,
      refetchAudio,

      handleSearchSubmit,
      searchTerm,
    ],
  );

  return (
    <AudiosContext.Provider value={contextValue}>
      { children }
    </AudiosContext.Provider>
  );
}
