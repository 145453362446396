import React, { useContext } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { AudioContextType } from '../../../../dorian-shared/types/audio/audio';
import {
  AudioStepAction,
  SFXActionSettings,
} from '../../../../dorian-shared/types/audio/AudioStepSettingsType';
import { AudiosContext } from '../../AudioLibraryPage/AudioLibraryContainer/AudiosProvider/AudiosProvider';
import { AudioPlay } from '../AudioPlay/AudioPlay';
import { AudioSFXSelect } from '../AudioSFXSelect/AudioSFXSelect';

type SFXContainerProps = {
  value: SFXActionSettings,
  onChange: (value: SFXActionSettings) => void,
}

export function AudioSFXContainer(props: SFXContainerProps) {
  const { value, onChange } = props;

  const { audios } = useContext<AudioContextType>(AudiosContext);

  return (
    <>
      <Alert variant="info" className="text-center p-0 mb-2">
        Sound Effect
      </Alert>
      <Row>
        <Col>
          <AudioSFXSelect
            value={value}
            onChange={onChange}
          />
        </Col>
        <Col sm="auto">
          <AudioPlay
            url={audios.find((audio) => audio.id === value.audioId)?.audioUrl ?? ''}
            disabled={value.action === AudioStepAction.None
            || value.action === AudioStepAction.Stop
            || value.audioId === null}
          />
        </Col>
      </Row>
    </>
  );
}
